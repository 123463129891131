/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { initializeApp } from 'firebase/app';

export const onClientEntry = () => {
    const config = {
        apiKey: process.env.GATSBY_API_KEY,
        authDomain: process.env.GATSBY_AUTH_DOMAIN,
        projectId: process.env.GATSBY_PROJECT_ID,
        storageBucket: process.env.GATSBY_STORAGE_BUCKET,
        messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
        appId: process.env.GATSBY_APP_ID,
        measurementId: process.env.GATSBY_MEASUREMENT_ID
    };
    initializeApp(config);
};